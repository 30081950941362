import { brStates } from "../constants/br-states.constants";
import { actingTypes, principalOcupationOptions } from "../constants/cnpj.constants";

export namespace UserFormStepBuilder {
    export const form = {
        steps: [
            {
                key: 0,
                title: 'Dados Pessoais',
                sections: [
                    {
                        key: 'details',
                        label: 'Dados Pessoais',
                        description: 'Precisamos dos seus dados pessoais para iniciar a abertura do seu MEI, mas fique tranquilo aqui seus dados estão seguros e serão utilizados para fins de uso interno em nossa plataforma.',
                        inputs: [
                            {
                                label: 'Seu Nome Completo *',
                                id: 'fullName',
                                type: 'text',
                                required: true,
                                placeholder: 'Digite seu nome completo',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'Endereço de e-mail *',
                                id: 'email',
                                type: 'email',
                                required: true,
                                placeholder: 'Digite seu e-mail',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'CPF *',
                                placeholder: '000.000.000-00',
                                id: 'cpf',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                mask: '999.999.999-99',
                            },
                            {  
                                label: 'Número de Telefone (WhatsApp)',
                                id: 'phone',
                                placeholder: '(00) 00000-0000',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                mask: '(99) 99999-9999',
                            },
                          
                            {
                                label: 'RG / Identidade *',
                                id: 'rg',
                                type: 'number',
                                required: true,
                                placeholder: 'Digite o número do RG',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Órgão Emissor do RG *',
                                id: 'rgEmissor',
                                placeholder: '(Ex: SSP)',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado de Emissão do seu RG *',
                                id: 'rgStateEmissor',
                                type: 'select',
                                placeholder: 'Selecione o estado de emissão do seu RG',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Nome Completo da Mãe *',
                                id: 'mothername',
                                type: 'text',
                                placeholder: 'Digite o nome completo da mãe',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'Data de Nascimento *',
                                id: 'birthdate',
                                placeholder: '00/00/0000',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                mask: '99/99/9999'
                            },
                        ]
                    },
                ]
            },
            {
                key: 1,
                title: 'Dados CNPJ',
                sections: [
                    {
                        key: 'cnpjData',
                        label: 'Dados CNPJ',
                        description: '',
                        inputs: [
                            {
                                label: 'Nome Fantasia',
                                placeholder: 'Digite o nome da sua empresa',
                                id: 'fantasyName',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                },
                            },
                            {
                                label: 'Capital Social (Valor Inicial em Caixa da Empresa)',
                                placeholder: 'Ex: R$ 1000,00',
                                id: 'socialCapital',
                                type: 'number',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Ocupação Principal da Empresa *',
                                placeholder: 'Selecione a ocupação principal da empresa',
                                id: 'principalOcupation',
                                required: true,
                                type: 'select',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: principalOcupationOptions
                            },
                            {
                                label: 'Escolha a Ocupação Secundária (até 15 opções)',
                                placeholder: 'Selecione as ocupações secundárias',
                                id: 'secondaryOcupations',
                                required: true,
                                isMultiple: true,
                                type: 'select',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: principalOcupationOptions
                            },
                            {
                                label: 'Forma de atuação *',
                                placeholder: 'Selecione a forma de atuação da sua empresa',
                                id: 'actingType',
                                required: true,
                                type: 'select',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: actingTypes
                            },
                        ]
                    }
                ]
            },
            {
                key: 2,
                title: 'Endereço',
                sections: [
                    {
                        key: 'personalAddress',
                        label: 'Endereço Pessoal',
                        description: '',
                        inputs: [
                            {
                                label: 'CEP',
                                id: 'zipcode',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                placeholder: '00000-000',
                                mask: '99999-999',
                            },
                            {
                                label: 'Cidade',
                                placeholder: 'Digite a sua cidade',
                                id: 'city',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Bairro',
                                placeholder: 'Digite o seu bairro',
                                id: 'district',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Endereço (rua, avenida, etc...)',
                                placeholder: 'Digite o seu endereço',
                                id: 'street',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Número',
                                id: 'number',
                                type: 'number',
                                required: true,
                                placeholder: 'Ex: 109',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado',
                                id: 'state',
                                placeholder: "Selecione o estado",
                                type: 'select',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Complemento',
                                id: 'complement',
                                type: 'text',
                                required: false,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                                placeholder: '(Ex: Perto da escola)'
                            },
                        ]
                    },
                    {
                        key: 'comercialAddress',
                        label: 'Endereço Comercial',
                        description: '',
                        inputs: [
                            {
                                label: 'CEP',
                                id: 'comercialAddressZipcode',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                placeholder: '00000-000',
                                mask: '99999-999',
                            },
                            {
                                label: 'Cidade',
                                placeholder: 'Digite a sua cidade',
                                id: 'comercialAddressCity',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Bairro',
                                placeholder: 'Digite o seu bairro',
                                id: 'comercialAddressDistrict',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Endereço',
                                placeholder: 'Digite o seu endereço',
                                id: 'comercialAddressStreet',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Número',
                                id: 'comercialAddressNumber',
                                type: 'number',
                                required: true,
                                placeholder: 'Ex: 109',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado',
                                id: 'comercialAddressState',
                                type: 'select',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Complemento',
                                id: 'comercialAddressComplement',
                                type: 'text',
                                required: false,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                                placeholder: '(Ex: Perto da escola)'
                            },
                        ]
                    }
                ]
            },
            {
                key: 4,
                title: 'Pagamento',
                sections: [
                    {
                        key: 'govAccess',
                        label: 'Acesso GOV.BR',
                        description: '',
                        inputs: [
                            {
                                label: 'CPF',
                                placeholder: '000.000.000-00',
                                id: 'cpf',
                                type: 'email',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                            },
                            {
                                label: 'Confirme a senha GOV.BR (BRASIL CIDADÃO)',
                                placeholder: 'Digite a sua senha de acesso gov.br',
                                id: 'govPassword',
                                type: 'password',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                            },
                        ]
                    }
                ]
            }, 
        ]
    }
}