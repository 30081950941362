import React from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
    sx?: Object,
};

const Logo: React.FC<Props> = ({ sx }) => {
    return (
        <Stack flexDirection="row" mt={-3} display="flex" alignContent={"center"} alignItems={"center"} justifyItems={"center"} justifyContent={'center'}>
           <img alt="logo" src="/static/illustrations/semei-oficial-logo.png" style={{ height: 80 }} />
           <Typography variant='h4' fontWeight={'700'} fontStyle={'normal'} fontFamily={'Inter'}>CADASTRO DO MEI</Typography>
        </Stack>
    )
}

export default Logo;