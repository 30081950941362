import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from '@mui/lab';
import { Card, useTheme, useMediaQuery, Alert, MobileStepper, AlertTitle, Box, Button, Checkbox, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, Step, StepButton, Stepper, Tooltip, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { When } from 'react-if';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cards from 'react-credit-cards';
import { useNavigate } from "react-router-dom";

import { CreditCardFormBuild } from '../../builders/credit-card-form.builder';
import { UserFormStepBuilder } from '../../builders/user-form-step.builder';
import { Page } from '../../components';
import FormBuilder from '../../components/form-builder';
import { CreateCnpjStepOne } from '../../components/forms/create-cnpj-step-form/step-1';
import { CreateCnpjStepTwo } from '../../components/forms/create-cnpj-step-form/step-2';
import { CreateCnpjStepThree } from '../../components/forms/create-cnpj-step-form/step-3';
import { CredentialsStepFour } from '../../components/forms/create-cnpj-step-form/step-4';
import { RegexFactory } from '../../factory/regex.factory';
import { requestViaCep } from '../../helpers/useViaCep';
import { api } from '../../http';
import { RootState, store } from '../../store';
import { InputForm } from '../../types';
import { Customer, GetPaymentDetailsResponse, RequestCustomerPaymentResponse } from '../../types/customer.types';
import { FormOnboard } from '../../types/form-onboard.types';
import { stepOneValidation, stepThreeOnlyPersonalValidation, stepTwoValidation } from '../../validations/form.validations';
import { AppIndexStyles } from './styles';
import useInterval from "../../hooks/useInterval";
import { CustomerService } from "../../services/customer.service";
import { PixPayModal } from "../../components/pix-pay-modal";

//Simplifica Checkout - Open Mei Step 1 
//checkout-open-mei-step-1

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [discountShow, setDiscountShow] = useState(false);
  const { form, actualStep: ac, customer, done } = useSelector((state: RootState) => state.onboard);
  const [actualStep, setActualStep] = useState<number>(ac);
  const [paymentType, setPaymentType] = useState<'credit_card' | 'bill' | 'pix'>();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [paymentData, setPaymentData] = useState<any>();
  const PRICE = 173;
  const [loading, setLoading] = useState({
    payment: false,
    createCustomer: false,
  });
  const [converted, setConverted] = useState(false);
  const [gtmClass, setGtmClass] = useState<undefined | string>(undefined);
  const [modal, setModal] = useState({
    pay: false,
    terms: false,
  });

  const validationSchemaPerStep: Record<number, any> = {
    0: stepOneValidation,
    1: stepTwoValidation,
    2: stepThreeOnlyPersonalValidation,
  }
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const toggleLoading = (key: keyof typeof loading, value: boolean) => setLoading((l) => ({ ...l, [key]: value }));
  const toggleModal = (key: keyof typeof modal, value: boolean) => setModal((l) => ({ ...l, [key]: value }));

  const formik = useFormik<FormOnboard>({
    validationSchema: validationSchemaPerStep[actualStep],
    initialValues: {
      ...form,
    },
    onSubmit: async (values) => {}
  });

  const { 
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
    getFieldProps,
    validateForm,
    resetForm,
  } = formik;

  const createCustomer = async () => {
    toggleLoading('createCustomer', true);

    await CustomerService.createCustomer(values, (isValid, msg) => {
      toggleLoading('createCustomer', false);
      if (isValid) {
        setActualStep(step => step + 1);
      }
    });
  }

  const generatePayment = async () => {
    toggleLoading('payment', true);
    
    let payload: any = { type: paymentType };

    if (paymentType === 'credit_card') {
      payload.paymentData = {
        holderName: values.holderName,
        cardNumber: values.cardNumber,
        expirationDate: values.expirationDate,
        securityCode: values.securityCode,
        installmentQuantity: values.installmentQuantity
      };
    }

    const res = await api.post<
      RequestCustomerPaymentResponse, 
      AxiosResponse<RequestCustomerPaymentResponse>
    >(`/payments/${customer?.uuid}`, { ...payload });

    toggleLoading('payment', false);

    if (!res.data.isValid) {
      toast(res.data.message, { type: 'error' });
      
      return;
    }

    if (res.data.isValid) {
      setPaymentData({ ...res.data.result, type: paymentType });

      if (paymentType === 'pix') {
        toggleModal('pay', true);
      }
      else if (paymentType === 'credit_card') {
        setActualStep(4);
        (window as any).gtag('event', 'conversion', {
          'send_to': 'AW-11172535327/-oimCL69wK4YEJ-4vc8p',
          'transaction_id': ''
        });
      }
    }

    toggleLoading('payment', false);
    return;
  }

  const handleNextStep = async () => {
    const result = await validateForm(values);

    const stepWithErrors = Object.keys(result)?.length > 0;

    if (!stepWithErrors) {
      if (actualStep === 0) {
        if (Object.keys(customer).length === 0) {
          await createCustomer();
          return;
        }

        await CustomerService.updateCustomer(customer.uuid, values);
        setActualStep(step => step + 1);
        return;
      }

      if (actualStep === 1 || actualStep === 2) {
        await CustomerService.updateCustomer(customer.uuid, values);

        setActualStep(step => step + 1);
        return;
      }

      if (actualStep === 3) {
        await generatePayment();
        return;
      }

      if(actualStep === 4) {
        await CustomerService.updateCustomer(customer.uuid, values);
        store.dispatch.onboard.setDone(true);
        toast('Tudo certo, agora basta aguardar o contato e a finalização da abertura do seu CNPJ!', { type: 'success' });
        return;
      }
    }
  };

  const handlePreviousStep = () => {
    setActualStep(step => step - 1);
  };

  useEffect(() => {
    toggleLoading('payment', false);
    store.dispatch.onboard.setForm(values);
  }, [values]);

  useEffect(() => {
    store.dispatch.onboard.setActualStep(actualStep);
  }, [actualStep]);

  useEffect(() => {
    if (RegexFactory.onlyNumbers(values?.zipcode!)?.length === 8) {
      (async () => {
        const address = await requestViaCep(RegexFactory.onlyNumbers(values?.zipcode!));
        setValues({
          ...values,
          ...address,
        });
      })();
    }
  }, [values?.zipcode])

  useEffect(() => {
    if (RegexFactory.onlyNumbers(values?.comercialAddressZipcode!)?.length === 8) {
      (async () => {
        const address = await requestViaCep(RegexFactory.onlyNumbers(values?.comercialAddressZipcode!));

        setValues({
          ...values,
          comercialAddressCity: address?.city,
          comercialAddressComplement: address?.complement,
          comercialAddressDistrict: address?.district,
          comercialAddressStreet: address?.street,
          comercialAddressState: address?.state,
          comercialAddressZipcode: address?.zipcode,
        });
      })();
    }
  }, [values?.comercialAddressZipcode])

  useInterval(() => {
    (async () => {
      if (paymentData && paymentData.type === 'pix' && actualStep !== 4 && actualStep !== 5) {
        const res = await api.get<GetPaymentDetailsResponse, AxiosResponse<GetPaymentDetailsResponse>>(`/payments/${paymentData.id}`);
      
        if (res.data.result.status === 'complete') {
          if (!converted) {
            (window as any)?.gtag('event', 'conversion', {
              'send_to': 'AW-11172535327/q2OWCLu9wK4YEJ-4vc8p',
              'transaction_id': res.data.result.txid
            });
            setConverted(true);
          }
          
          toggleModal('pay', false);
          toast('Pagamento realizado com sucesso!', { type: 'success' });
          setActualStep(4);
        }
      }
    })(); 
  }, 5000);

  return (
    <>
      <Page title="Cadastro do MEI - Abrir meu MEI">
        <Container fixed id={gtmClass} maxWidth={false} sx={{ mt: isSmallScreen ? 2 : 5 }}>
          <When condition={actualStep !== 5 && !isSmallScreen && !done}>
            <Card style={AppIndexStyles.stepperContainer}>
              <Stepper activeStep={actualStep} style={{ marginTop: 10 }}>
                {UserFormStepBuilder.form.steps.map((step) => 
                  <Step key={step?.key}>
                    <StepButton color="inherit" style={{ fontFamily: 'Inter' }}>
                      {step?.title}
                    </StepButton>
                  </Step>
                )}
              </Stepper >
            </Card>
          </When>

          <When condition={isSmallScreen && actualStep !== 5 && actualStep !== 4 && !done && !modal.pay}>
            <MobileStepper 
              steps={UserFormStepBuilder.form.steps?.length} 
              activeStep={actualStep}
              nextButton={
                <LoadingButton 
                  id={actualStep === 3 ? (paymentType === 'pix' ? "generate-pix-payment" : "generate-cc-payment") : 'next-step'}
                  onClick={handleNextStep} 
                  variant='contained' 
                  color='secondary' 
                  style={{ color: 'white' }} 
                  loading={loading.payment} 
                  disabled={actualStep === 3 ? !paymentType || !agreeTerms : false}
                >
                  {actualStep === 3 ? 'Pagar Agora' : 'Próximo'}
                </LoadingButton>
              }
              backButton={
                <Button onClick={handlePreviousStep} variant='outlined' sx={{ mr: 1 }} color='secondary' disabled={loading.createCustomer || actualStep === 0}>
                  Voltar
                </Button>
              }
            />
          </When>
        
          <When condition={done}>
            <Container fixed sx={{ mb: 2 }}>
              <Alert severity="success">  
                <AlertTitle>Seu pedido foi realizado com sucesso!</AlertTitle>
                <Typography mt={2} color={'#155915'} fontWeight={500} fontSize={16}>
                  Estamos analisando seus dados e em breve você receberá um e-mail no endereço <strong>{values?.email}</strong> e um WhatsApp no seu telefone <strong>{values?.phone}</strong> com todos os detalhes do seu pedido.
                </Typography>
              </Alert>

              <Button 
                sx={{ mt: 10 }} 
                fullWidth 
                type='button' 
                variant="contained"
                onClick={() => {
                  resetForm();
                  setPaymentData(null);
                  store.dispatch.onboard.setForm( {
                    secondaryOcupations: [],
                    personalAddressIsSameComercial: 'true',
                  } as FormOnboard);
                  store.dispatch.onboard.setCustomer({} as Customer);
                  setActualStep(0);
                  store.dispatch.onboard.setActualStep(0);
                  store.dispatch.onboard.setDone(false);
                }}
              >
                Quero abrir outro MEI
              </Button>
            </Container>
          </When>

          <When condition={!done}>
            <When condition={actualStep === 3 && discountShow}>
              <Alert sx={{ mb: 2 }} onClose={() => setDiscountShow(false)}>
                Estamos com um desconto de <strong>20%</strong> para pagamentos via <strong>PIX</strong>, aproveite 🥳  🥳
              </Alert>
            </When>
              <Card style={{ padding: 10, paddingBottom: isSmallScreen ? 50 : 10 }}>
                {UserFormStepBuilder.form.steps.map((step) => (
                  <>
                    <When condition={actualStep === 0 && step.key === 0}>
                      {step?.sections?.map((section, sectionIndex) => (
                        <CreateCnpjStepOne
                          description={section?.description}
                          stepKey={section?.key}
                          inputs={section?.inputs as InputForm[]}
                          sectionIndex={sectionIndex}
                          label={section?.label} 
                          errors={errors}
                          getFieldProps={getFieldProps}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                        />
                      ))}
                    </When>
                    <When condition={actualStep === 1 && step.key === 1}>
                      {step?.sections?.map((section, sectionIndex) => (
                        <CreateCnpjStepTwo 
                          stepKey={section?.key}
                          description={section?.description}
                          inputs={section?.inputs as InputForm[]}
                          sectionIndex={sectionIndex}
                          label={section?.label} 
                          errors={errors}
                          getFieldProps={getFieldProps}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                        />
                      ))}
                    </When>
                    <When condition={actualStep === 2 && step.key === 2}>
                      {step?.sections?.map((section, sectionIndex) => (
                        <CreateCnpjStepThree 
                          stepKey={section?.key}
                          inputs={section?.inputs as InputForm[]}
                          sectionIndex={sectionIndex}
                          label={section?.label} 
                          errors={errors}
                          getFieldProps={getFieldProps}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                        />
                      ))}
                    </When>
                    
                    <When condition={actualStep === 4 && step.key === 4}>
                      <div id="purchase-completed">
                        {step?.sections?.map((section, sectionIndex) => (
                          <CredentialsStepFour 
                            stepKey={section?.key}
                            inputs={section?.inputs as InputForm[]}
                            sectionIndex={sectionIndex}
                            label={section?.label} 
                            errors={errors}
                            getFieldProps={getFieldProps}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                          />
                        ))}
                        <Stack display="flex" flexDirection={"row-reverse"} mt={4} mb={2}>
                          <LoadingButton onClick={handleNextStep} variant='contained' color='secondary' loading={loading.createCustomer}>
                            Validar acesso
                          </LoadingButton>
                        </Stack>
                      </div>
                    </When>
                  </>
                ))}
                
                <When condition={actualStep === 3}>
                  
                  <Box style={{ margin: 15 }} id="checkout-open-mei-step-4">
                    <Typography color={'#2D2935'} marginBottom={4} marginTop={2} fontSize={22} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                      Pagamento Único
                    </Typography>
                    <Box style={{ marginTop: 40, background: 'white', padding: 25 }}>
                      <Stack display="flex" flexDirection={isSmallScreen ? "column" : "row"} justifyContent={isSmallScreen ? "center" : "space-between"}>
                        <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                          <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                            Produto
                          </Typography>
                          <Typography mt={2} color="#737185" fontSize={14} textAlign={'left'}>
                            Abertura do CNPJ MEI
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                          <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                            Quantidade
                          </Typography>
                          <Typography mt={isSmallScreen ? 0 : 2} color="#737185" fontSize={14} textAlign={isSmallScreen ? "left" : 'center'}>
                            1
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} mt={isSmallScreen ? 2 : 0}>
                          <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'}>
                            Subtotal
                          </Typography>
                          <Typography mt={2} color="#737185" fontSize={14} textAlign={'left'}>
                            {PRICE.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                          </Typography>
                        </Grid>
                      </Stack>
                      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                      <Stack display="flex" flexDirection={"row"} justifyContent={"flex-end"}>
                        <Typography color="#3A3847" fontSize={14} fontWeight={600} textAlign={'left'} mr={2}>
                          Total
                        </Typography>
                        <Typography color="#737185" fontSize={14} textAlign={'left'}>
                          {PRICE.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </Typography>
                      </Stack>
                    </Box>
                    <Typography color={'#3A3847'} marginBottom={2} marginTop={6} fontSize={16} fontWeight={600}>
                      Formas de pagamento
                    </Typography>
                    <Typography mt={1} fontSize={14} fontFamily={'Inter'} fontStyle={'normal'}>
                      O processo do seu CNPJ MEI será iniciado após a confirmação do pagamento.
                    </Typography>

                    <Box style={{ marginTop: 40, marginBottom: 40, background: 'white', padding: 25 }}>                    
                      <Divider style={{ marginBottom: 15, marginTop: 15 }} />

                      <Stack display="flex" flexDirection="row" justifyContent={"flex-start"} alignContent={"center"} alignItems={"center"}>
                        <Checkbox 
                          checked={paymentType === 'credit_card'} 
                          onChange={(_, c) => {
                            if (c === false) {
                              setPaymentType(undefined);
                              return;
                            }

                            setPaymentType('credit_card')
                          }} 
                        />
                        <Stack flexDirection={"row"} display="flex" ml={1}>
                          <img style={{ marginRight: 10 }} alt="Ilustração cartão de crédito abrir mei" src={"/static/illustrations/credit_card.svg"} />
                          <Typography color="#3A3847" fontSize={14} fontWeight={600} fontFamily={'Inter'} fontStyle={'normal'}>
                            Cartão de crédito
                          </Typography>
                        </Stack>
                      </Stack>

                      <When condition={paymentType === 'credit_card'}>
                        <Grid container mt={2} mb={4}>
                          <Grid item md={6}>
                            <FormBuilder
                              errors={errors}
                              touched={touched}
                              values={values}
                              setFieldValue={setFieldValue}
                              inputs={CreditCardFormBuild.form}
                              getFieldProps={getFieldProps}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Cards
                              cvc={values?.securityCode || ''}
                              expiry={values?.expirationDate || ''}
                              name={values?.holderName || ''}
                              number={values?.cardNumber || ''}
                              locale={{ valid: 'válido até'}}
                              placeholders={{ name: 'NOME' }}
                            />
                          </Grid>
                        </Grid>
                      </When>

                      <Divider style={{ marginBottom: 15, marginTop: 15 }} />

                      <Stack display="flex" flexDirection="row" justifyContent={"flex-start"} alignContent={"center"} alignItems={"center"}>
                        <Checkbox 
                          checked={paymentType === 'pix'} 
                          onChange={(_, c) => {
                            if (c === false) {
                              setPaymentType(undefined);
                              return;
                            }

                            setPaymentType('pix')
                          }} 
                        />
                        <Stack flexDirection={"row"} display="flex" ml={1}>
                          <img style={{ marginRight: 10 }} alt="Ilustração pix abrir mei" src={"/static/illustrations/ic_outline-pix.svg"} />
                          <Typography color="#3A3847" fontSize={14} fontWeight={600} fontFamily={'Inter'} fontStyle={'normal'}>
                            PIX
                          </Typography>
                        </Stack>
                        <Typography>
                        </Typography>
                      </Stack>
                      <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                    </Box>
                    <Typography style={{ marginBottom: 20 }} fontSize={14} mr={4} ml={1} mt={1} textAlign={'left'} color='#605E70'>
                      Os seus dados pessoais serão utilizados para processar a sua compra, apoiar a sua experiência em todo este site e para outros fins descritos na nossa <a onClick={() => {
                        window.open('https://www.simplificamei.com/politica-de-privacidade');
                      }}>política de privacidade.</a>
                    </Typography>
                    <Stack display="flex" flexDirection={"row"} ml={-1} style={{ marginBottom: isSmallScreen ? 70 : 20 }}>
                      <Checkbox checked={agreeTerms} onChange={(e, v) => setAgreeTerms(v)} />
                      <Typography fontSize={14} mr={4} mt={1} textAlign={'left'} color='#605E70'>
                        Li e concordo com os <a href="#" onClick={() => {
                        window.open('https://www.simplificamei.com/termos-de-uso');
                      }}>termos e condições</a> do site
                      </Typography>
                    </Stack>
                      <When condition={!isSmallScreen}>
                        <LoadingButton id={paymentType === 'pix' ? "generate-pix-payment" : "generate-cc-payment"} sx={{ mb: isSmallScreen ? 5 : 0 }} loading={loading.payment} disabled={!paymentType || !agreeTerms} onClick={handleNextStep} variant='contained' color='success' fullWidth style={{ color: 'white' }}>
                          Pagar Agora
                        </LoadingButton>
                      </When>
                  </Box>
                </When>
              </Card>
            <When condition={!isSmallScreen && actualStep !== 4}>
              <>
                <Stack display={'flex'} flexDirection={'row-reverse'} justifyContent={'space-between'} mt={2} mb={3}>      
                  <When condition={actualStep !== 4 && actualStep !== 3}>
                    <LoadingButton size="large" onClick={handleNextStep} variant='contained' color='secondary' loading={loading.createCustomer}>
                      Próximo
                    </LoadingButton>
                  </When>
                  <When condition={actualStep !== 0}>
                    <Button size="large" onClick={handlePreviousStep} variant='outlined' sx={{ mr: 1, mb: 3 }} color='secondary' disabled={loading.createCustomer}>
                      Voltar
                    </Button>
                  </When>
                </Stack>
              </>
            </When>
          </When>
          
        </Container>
      </Page>

      <PixPayModal
        visible={modal.pay}
        onHide={() => toggleModal('pay', false)}
        paymentData={paymentData}
      />
    </>
  )
}

export default Home
